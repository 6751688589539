#meeting-report {
  .menu-tab {
    margin-top: 1rem;
    .menu {
      margin: 0;
      border: none;
      .item {
        font-weight: bold;
        color: black;
      }
      .active {
        color: black !important;
        text-decoration: underline;
      }
    }
    .tab {
      margin: 0;
      max-height: 350px;
      overflow-y: scroll;
    }
  }
}