@use "../elements/profilePic";
@use "../elements/spanLink";

#manage-project-jobs {
  .search-section {
    float: left;
  }
  .top-share-section {
    margin-bottom: 1rem;
    text-align: end;
  }
  .bottom-share-section {
    margin-top: 5rem;
    margin-bottom: 3rem;
    text-align: center;
    .description {
      margin-bottom: 1.5rem;
    }
  }
  .search-jobs-result {
    padding-top: 4rem;
  }
  .requests {
    .request {
      @extend %profile-info-row-format;
      .expert-profile-pic {
        margin-right: 1rem;
      }
      .status {
        float: right;
        font-weight: bold;
      }
      .span-link {
        .header {
          .link-btn {
            margin-left: 0.25rem;
          }
        }
        .meta {
          font-weight: normal;
          width: 90%;
        }
      }
      .description {
        padding-top: 1rem;
        margin-left: 7rem;
        .information {
          margin-bottom: 0.5rem;
          .link {
            display: inline-block;
            text-decoration: underline;
            margin-right: 1rem;
          }
        }
      }
      .actions {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        .list {
          margin-left: auto;
          .item {
            margin: 0;
          }
        }
      }
      .bonus {
        padding-top: 0;
      }
    }
    .search-result {
      .extra {
        height: 50%;
        overflow-y: auto;
        scrollbar-width: thin;
      }
    }
  }
  .not-found {
    padding: 10rem;
  }
}